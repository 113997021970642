<template>
  <div>
    <app-fonts></app-fonts>
    <app-typography></app-typography>
    <app-messages></app-messages>
    <app-margins></app-margins>
    <app-padding></app-padding>
    <app-buttons></app-buttons>
    <app-modals></app-modals>
    <app-images></app-images>
    <app-forms></app-forms>
    <app-backgrounds></app-backgrounds>
    <app-navigation></app-navigation>
    <app-layout></app-layout>
    <app-helpers></app-helpers>
    <app-material-icons></app-material-icons>
    <app-icons></app-icons>
    <app-tables></app-tables>
  </div>
</template>

<script>
import Fonts from '@/components/Ztyles/Fonts.vue';
import Typography from '@/components/Ztyles/Typography.vue';
import Messages from '@/components/Ztyles/Messages.vue';
import Margins from '@/components/Ztyles/Margins.vue';
import Padding from '@/components/Ztyles/Paddings.vue';
import Buttons from '@/components/Ztyles/Buttons.vue';
import Modals from '@/components/Ztyles/Modals.vue';
import Images from '@/components/Ztyles/Images.vue';
import Forms from '@/components/Ztyles/Forms.vue';
import Backgrounds from '@/components/Ztyles/Backgrounds.vue';
import Navigation from '@/components/Ztyles/Navigations.vue';
import Layouts from '@/components/Ztyles/Layouts.vue';
import Helpers from '@/components/Ztyles/Helpers.vue';
import MaterialIcons from '@/components/Ztyles/MaterialIcons.vue';
import Icons from '@/components/Ztyles/Icons.vue';
import Tables from '@/components/Ztyles/Tables.vue';

export default {
  name: 'styles',
  components: {
    appFonts: Fonts,
    appTypography: Typography,
    appMessages: Messages,
    appMargins: Margins,
    appPadding: Padding,
    appButtons: Buttons,
    appModals: Modals,
    appImages: Images,
    appForms: Forms,
    appBackgrounds: Backgrounds,
    appNavigation: Navigation,
    appLayout: Layouts,
    appHelpers: Helpers,
    appMaterialIcons: MaterialIcons,
    appIcons: Icons,
    appTables: Tables,
  },
};
</script>
